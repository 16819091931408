import { useEffect, useState } from "react";
import "./NewCategory.css";
import category1 from "../../../Components/Images/categories/1.jpg";
import category2 from "../../../Components/Images/categories/2.jpg";
import category3 from "../../../Components/Images/categories/3.jpg";
import category4 from "../../../Components/Images/categories/4.jpg";
import { apiLoadingSubject, BannerStore } from "../../../Store";
import { PositionEnum } from "../../../Model/Enums/PositionEnum";
import Carousel from "react-material-ui-carousel";
import { useNavigate } from "react-router";
import { Subscription } from "rxjs";
import {
  ApiCallResponse,
  PageEventTypeEnum,
} from "../../../Model/ApiCallResponse";
import { ApiCallState } from "../../../Model/Enums/ApiCallState";
import { messageConstants } from "../../../StringConstants";
import { SearchParamsService } from "../../../Services/SearchParamsService";
import { showErrorToast } from "../../../utils/Toastify/ToastifyHandler";

const NewCategory = () => {
  const [isBannerLoading, setIsBannerLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const navigateToProduct = (id: number, desc: string) => {
    navigate(`/product?`);
    SearchParamsService.setParams([
      { key: "cid", value: id.toString() },
      { key: "isDefaultCatalogue", value: "true" },
      { key: "brandIds", value: "" },
      { key: "sCid", value: "" },
      { key: "selectedScid", value: "" },
      { key: "selectedCid", value: "" },
      { key: "productName", value: "" },
      { key: "category", value: desc + " Products" },

    ]);
  };
  const fileUrls = [
    "https://nutritap.s3.ap-south-1.amazonaws.com/kiosk/offers/Corporate+Gifting.pdf"
  ];

  const openExternalLink = (url: string) => {
    window.open(url, "_blank");
  };

  const navigateToViewBrochure = () => {
    fileUrls.forEach((link) => {
      openExternalLink(link);
    });
  };

  useEffect(() => {
    let bannerSubscriber: Subscription = apiLoadingSubject.subscribe(
      (response: ApiCallResponse) => {
        if (PageEventTypeEnum.BANNER === response.eventType) {
          switch (response.apiCallState) {
            case ApiCallState.LOADING:
              setIsBannerLoading(true);
              break;
            case ApiCallState.LOADING_SUCCESS:
              setIsBannerLoading(false);
              break;
            case ApiCallState.LOADING_ERROR:
              showErrorToast(messageConstants.REFRESH);
              setIsBannerLoading(false);
              break;
          }
        }
      }
    );
    return () => {
      if (bannerSubscriber) {
        bannerSubscriber.unsubscribe();
      }
    };
  }, []);

  const categories = [
    {
      id: 3,
      imgURL: category1,
      position: PositionEnum.MAIN_CAT_1,
      desc: "Stationery",
    },
    {
      id: 5,
      imgURL: category2,
      position: PositionEnum.MAIN_CAT_2,
      desc: "Pantry",
    },
    {
      id: 6,
      imgURL: category3,
      position: PositionEnum.MAIN_CAT_3,
      desc: "Housekeeping",
    },
  ]; 
  return (
    <div>
      <div className="container">
        <div className="mt-2 mt-sm-3">
          <div className="row gx-2 gy-2 gx-sm-3 gy-sm-3">
            {categories.map((cat) => {
              return (
                <div className="col-6 col-md-3 col-lg-4" key={cat.id}>
                  <h5 className="text-capitalize fw-bold black_clr">
                    {cat.desc}
                  </h5>
                  <a className="category_item">
                    <Carousel
                      autoPlay={true}
                      swipe={true}
                      // navButtonsAlwaysVisible={true}
                      animation={"fade"}
                      interval={3000}
                      duration={1000}
                      indicators={false}
                    >
                      {BannerStore.getBannerPositionMap(cat.position).map(
                        (item) => {
                          return (
                            <div className="category_item" key={item.id}>
                              <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                  <div className="heroSlider_content_desc">
                                    <div className="row">
                                      <div className="col-12 col-sm-8">
                                        <h1 className="gold_clr fw-500 mb-3">{""}</h1>
                                        <h4 className="black_clr fw-500 mb-3 mb-lg-4">
                                          {/* {item.desc} */}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                  <button
                                    className="cat-btn"
                                    onClick={() => navigateToProduct(cat.id,cat.desc)}
                                  >
                                    <img
                                      className="w-100 img-fluid category_item"
                                      src={item.bannerUrl}
                                      alt=""
                                    />
                                  </button>
                                </div>
                              </div>
                              <div className="swiper-pagination"></div>
                            </div>
                          );
                        }
                      )}
                    </Carousel>
                  </a>
                </div>
              );
            })}
            <div
              className="col-6 col-md-3 col-lg-12 brochure-wrapper"
              onClick={navigateToViewBrochure}
            >
              <a className="category_item smallSize_category">
                <h5 className="text-capitalize fw-bold black_clr">
                  Gifting solution
                </h5>
                <img
                  className="w-100 img-fluid category_item"
                  src={category4}
                  alt="category-img"
                />
              </a>
            </div>
            <div
              className="col-6 col-md-3 col-lg-12 category"
              onClick={navigateToViewBrochure}
            >
                <h5 className="text-capitalize fw-bold black_clr">
                  Gifting solution
                </h5>
              <a className="category_item smallSize_category">
                <img
                  className="w-100 img-fluid category_item"
                  src={category4}
                  alt="category-img"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCategory;

// import React, {useEffect, useState} from "react";
// import "./NewCategory.css";
// import category1 from "../../../Components/Images/categories/1.jpg";
// import category2 from "../../../Components/Images/categories/2.jpg";
// import category3 from "../../../Components/Images/categories/3.jpg";
// import category4 from "../../../Components/Images/categories/4.jpg";
// import NewHome from "../Home/NewHome";
// import {apiLoadingSubject, BannerStore, CatalogueStore} from "../../../Store";
// import {PositionEnum} from "../../../Model/Enums/PositionEnum";
// import {useNavigate} from "react-router";
// import {Subscription} from "rxjs";
// import {ApiCallResponse, PageEventTypeEnum} from "../../../Model/ApiCallResponse";
// import {ApiCallState} from "../../../Model/Enums/ApiCallState";
// import {showErrorMessage} from "../../../utils/AlertHandler";
// import {messageConstants} from "../../../StringConstants";
// import { PageTypeEnum } from "../../../Model/Enums/PageTypeEnums";
// import { Carousel } from "react-bootstrap";

// const NewCategory = () => {
//   const [isBannerLoading, setIsBannerLoading] = useState<boolean>(true);
//   const navigate = useNavigate();

//   const navigateToProduct = (id: number) => {
//     navigate(`/product?cid=${id.toString()}`);
//   }

//   useEffect(() => {
//     let bannerSubscriber: Subscription = apiLoadingSubject.subscribe(
//         (response: ApiCallResponse) => {
//           if (PageEventTypeEnum.BANNER === response.eventType) {
//             switch (response.apiCallState) {
//               case ApiCallState.LOADING:
//                 setIsBannerLoading(true);
//                 break;
//               case ApiCallState.LOADING_SUCCESS:
//                 setIsBannerLoading(false);
//                 break;
//               case ApiCallState.LOADING_ERROR:
//                 showErrorMessage(messageConstants.REFRESH);
//                 setIsBannerLoading(false);
//                 break;
//             }
//           }
//         }
//     );
//     return () => {
//       if (bannerSubscriber) {
//         bannerSubscriber.unsubscribe();
//       }
//     };
//   }, []);

//   const categories = [
//     {
//       id: 3,
//       imgURL: category1,
//       position: PositionEnum.MAIN_CAT_1,
//       desc: "Stationery",
//     },
//     {
//       id: 5,
//       imgURL: category2,
//       position: PositionEnum.MAIN_CAT_2,
//       desc: "Pantry",
//     },
//     {
//       id: 6,
//       imgURL: category3,
//       position: PositionEnum.MAIN_CAT_3,
//       desc: "Housekeeping",
//     },
//   ];

//   const scrollToTop = () => {
//     window.scrollTo(0, 0);
//   };
//   const navigateToCategory = (categoryName: string) => {
//     CatalogueStore.setSelectedCategoryName(categoryName);
//     navigate(`/${PageTypeEnum.CATEGORY}`);
//     scrollToTop();
//   };
//   const slides = [];

//   for (let i = 0; i < categories.length; i += 3) {
//     slides.push(
//       <Carousel.Item key={i}>
//         <div className="row">
//           {categories.slice(i, i + 3).map((item, idx) => (
//             <div className="col-4" key={idx}>
//               <button
//                 // onClick={() => navigateToCategory(item.category)}
//                 className="category_item"
//               >
//                 <h5 className="text-capitalize fw-bold black_clr">
//                   {/* {item.category} */}
//                 </h5>
//                 <img
//                   className="w-100 img-fluid"
//                   src={item.imgURL}
//                   alt="category-img"
//                 />
//               </button>
//             </div>
//           ))}
//         </div>
//       </Carousel.Item>
//     );
//   }
//   return (
//     <div>
//       <div className="container">
//         <div className="mt-2 mt-sm-3">
//           <div className="row gx-2 gy-2 gx-sm-3 gy-sm-3">

//             <Carousel controls={false}>
//               {slides}
//               {/* {categories.slice(0,3).map((item) => (
//                 <Carousel.Item>

//                   <div className="col-6 col-md-3 col-lg-4">
//                     <button
//                       onClick={() => navigateToCategory(item.category)}
//                       className="category_item"
//                     >
//                       <h5 className="text-capitalize fw-bold black_clr">
//                         {item.category}
//                       </h5>
//                       <img
//                         className="w-100 img-fluid"
//                         src={item.imgURL}
//                         alt="category-img"
//                       />
//                     </button>
//                   </div>

//                 </Carousel.Item>
//               ))} */}
//             </Carousel>
//             <div className="col-6 col-md-3 col-lg-12">
//               <a className="category_item smallSize_category">
//                 <h5 className="text-capitalize fw-bold black_clr">
//                   Gifting solution
//                 </h5>
//                 <img
//                   className="w-100 img-fluid"
//                   src={category4}
//                   alt="category-img"
//                 />
//               </a>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default NewCategory;
