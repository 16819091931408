import { useEffect, useState } from "react";
import "./NewHome.css";
import Carousel from "react-material-ui-carousel";
import { BannerService } from "../../../Services/BannerService";
import { Subscription } from "rxjs";
import { apiLoadingSubject, BannerStore } from "../../../Store";
import {
  ApiCallResponse,
  PageEventTypeEnum,
} from "../../../Model/ApiCallResponse";
import { ApiCallState } from "../../../Model/Enums/ApiCallState";
import { showErrorMessage } from "../../../utils/AlertHandler";
import { messageConstants } from "../../../StringConstants";
import { useNavigate } from "react-router";
import { PositionEnum } from "../../../Model/Enums/PositionEnum";
import { SearchParamsService } from "../../../Services/SearchParamsService";
import DiwaliBannerPopUp from "./DiwaliBannerPopUp";
import { B2BConstants } from "../../../B2BConstants";
import { BannerModel } from "../../../Model/HomePageDto/BannerModel";

const NewHome = () => {
  const [isBannerLoading, setIsBannerLoading] = useState<boolean>(true);
  const [banners, setBanners] = useState<BannerModel[]>([]);
  const [isDiwaliPopupOpen, setIsDiwaliPopupOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const bannerDataService = new BannerService();

  const getBanners = () => {
    bannerDataService.getBanner();
  };

  const navigateToProduct = (id: number) => {
    navigate(`/product?`);
    SearchParamsService.setParams([
      { key: "brandIds", value: id.toString() },
      { key: "isDefaultCatalogue", value: "true" },
      { key: "sCid", value: "" },
      { key: "cid", value: "" },
      { key: "selectedScid", value: "" },
      { key: "category", value: "" },

      { key: "productName", value: "" },
    ]);

    window.scrollTo(0, 0);
  };

  useEffect(() => {
    getBanners();
    let bannerSubscriber: Subscription = apiLoadingSubject.subscribe(
      (response: ApiCallResponse) => {
        if (PageEventTypeEnum.BANNER === response.eventType) {
          switch (response.apiCallState) {
            case ApiCallState.LOADING:
              setIsBannerLoading(true);
              break;
            case ApiCallState.LOADING_SUCCESS:
              const diwaliBanner: BannerModel = {
                id: 0,
                bannerUrl: B2BConstants.CORPORATE_GIFTING_BANNER,
                onClick: () => setIsDiwaliPopupOpen(true),
                position: PositionEnum.MAIN,
              };
              setBanners([
                diwaliBanner,
                ...BannerStore.getBannerPositionMap(PositionEnum.MAIN),
              ]);
              setIsBannerLoading(false);
              break;
            case ApiCallState.LOADING_ERROR:
              showErrorMessage(messageConstants.REFRESH);
              setIsBannerLoading(false);
              break;
          }
        }
      }
    );
    return () => {
      if (bannerSubscriber) {
        bannerSubscriber.unsubscribe();
      }
    };
  }, []);

  return (
    <div>
      <Carousel
        autoPlay={true}
        swipe={true}
        // navButtonsAlwaysVisible={true}
        animation={"fade"}
        interval={3000}
        duration={1000}
      >
        {banners.map((item) => {
          return (
            <div className="hero_slider" key={item.id}>
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="heroSlider_content_desc">
                    <div className="row">
                      <div className="col-12 col-sm-8">
                        <h1 className="gold_clr fw-500 mb-3"></h1>
                        <h4 className="black_clr fw-500 mb-3 mb-lg-3">
                          {/* {item.desc} */}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <img
                    className="w-100 img-fluid banner"
                    src={item.bannerUrl}
                    alt="banner-img"
                    //@ts-ignore
                    onClick={item.onClick || (() => navigateToProduct(item.brand?.id))}
                    // onClick={() => navigateToProduct(item.brand.id)}
                  />
                </div>
              </div>
              <div className="swiper-pagination"></div>
            </div>
          );
        })}
      </Carousel>
      <DiwaliBannerPopUp
        isOpen={isDiwaliPopupOpen}
        onClose={() => setIsDiwaliPopupOpen(false)}
      />
    </div>
  );
};

export default NewHome;
